import * as params from "./type";

import { AxiosPromise } from "axios";
import { ICodeData } from "@/panel/admin/Code/type";
import { IOrgGroupData } from "@/panel/Org/type";
import { IProjectDatas } from "@/panel/admin/Project/type";
import instance from "./instance";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  //로그인
  setLogin(params: params.ILoginParam): AxiosPromise {
    return instance.post("/authenticate", params);
  },
  setRegist(userInfo: params.IRegistParam): AxiosPromise {
    return instance.post("/regist", userInfo);
  },
  setIDCheck(username: params.ISetIDCheckParam): AxiosPromise {
    return instance.post("/regist/checkid", username);
  },
  setRefreshToken(params: params.IRefreshTokenParam): AxiosPromise {
    return instance.post("/refreshtoken", params);
  },
  getCodes({ id, project_id }: params.IGetCodesParam): AxiosPromise {
    const url = `/codes${
      typeof id !== "undefined" ? "/" + id : ""
    }?project_id=${project_id}`;

    return instance.get(url, { cache: true });
  },
  setLogOut(params: params.ILogOutParam): AxiosPromise {
    return instance.post("/signout", params);
  },
  //프로젝트
  getProjectList(): AxiosPromise {
    return instance.get("/projects", {
      cache: true,
    });
  },
  // 구성원 관리
  getOrgList(params: params.IOrgListParam): AxiosPromise<IOrgGroupData[]> {
    return instance.get("/organs", { params });
  },

  getOrgSearch({
    project_id,
    keyword,
  }: params.IGetCertificationDocList): AxiosPromise {
    const url = `/organs/search?project_id=${project_id}&keyword=${keyword}`;
    return instance.get(url);
  },

  getOrgArrList({ project_id }: params.IGetOrgArrListParam): AxiosPromise {
    return instance.get(`/organs/list?project_id=${project_id}`);
  },
  setOrgList({ projectId, data }: params.ISetOrgListParam): AxiosPromise {
    return instance.post(`/organs?project_id=${projectId}`, { data });
  },
  deleteOrgList({ id }: params.IDeleteOrgListParam): AxiosPromise {
    return instance.delete(`/organs/${id}`);
  },
  getOrgChart(params: params.IOrgChartParam): AxiosPromise {
    return instance.get("/organs/chart", { params });
  },
  getMemberList({ id, project_id }: params.IGetMemberListParam): AxiosPromise {
    const targetID = id === null || id === 0 ? "" : `/${id}`;

    return instance.get(`/members${targetID}?project_id=${project_id}`);
  },
  getMembers({
    project_id,
    data,
    organ_id,
    member_name,
    page,
    size,
    sort,
  }: params.IGetMembersParam): AxiosPromise {
    return instance.get(
      `/members/list?project_id=${project_id}&organ_id=${organ_id}&member_name=${encodeURIComponent(
        member_name
      )}&page=${page}&size=${size}&sort=${sort[0]},${sort[1]}`,
      { data }
    );
  },
  setMembers({ project_id, data }: params.IGetMembersParam): AxiosPromise {
    return instance.post(`/members/list?project_id=${project_id}`, {
      data,
    });
  },
  setMember({
    project_id,
    data,
    pageName,
  }: params.ISetMemberParam): AxiosPromise {
    const url =
      pageName === "managerList"
        ? `/member?project_id=${project_id}`
        : `/members/list?project_id=${project_id}`;

    return instance.post(url, data);
  },
  deleteMember({ id }: params.IDeleteMemberParam): AxiosPromise {
    return instance.delete(`/member/${id}`);
  },
  setProfileImageUpload(formData: unknown): AxiosPromise {
    return instance.post("/uploadImage", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },

  getRoll({ id }: params.IGetRollParam): AxiosPromise {
    return instance.get(`/member/${id}/roll`);
  },
  setRoll({ id, roll_id, data }: params.ISetRollParam): AxiosPromise {
    const rollUrl = roll_id !== null ? `/${roll_id}` : "";
    return instance.post(`/member/${id}/roll${rollUrl}`, data);
  },
  deleteRoll({ id, roll_id }: params.IDeleteRollParam): AxiosPromise {
    const rollUrl = roll_id !== null ? `/${roll_id}` : "";
    return instance.delete(`/member/${id}/roll${rollUrl}`);
  },
  // 자산
  getAssetGroups({ project_id }: params.IGetAssetGroups): AxiosPromise {
    return instance.get(`/asset/groups?project_id=${project_id}`);
  },
  setAssetGroup({ project_id, data }: params.ISetAssetGroup): AxiosPromise {
    return instance.post(`/asset/group?project_id=${project_id}`, data);
  },
  deleteAssetGroup({ id }: params.IDeleteAssetGroup): AxiosPromise {
    return instance.delete(`/asset/group/${id}`);
  },
  getAssetGroup({ project_id, id }: params.IGetAssetGroup): AxiosPromise {
    const url = `/asset/group/${id}?project_id=${project_id}`;

    return instance.get(url);
  },
  getAssetGroupFields({
    project_id,
    id,
  }: params.IGetAssetGroupFields): AxiosPromise {
    const url = `/asset/group/${id}?project_id=${project_id}`;

    return instance.get(url);
  },
  setAssetGroupFields({
    project_id,
    datas,
  }: params.ISetAssetGroupFields): AxiosPromise {
    const url = `/asset/group?project_id=${project_id}`;

    return instance.post(url, datas);
  },
  setAssetGroupCellExcelUpload({
    id,
    project_id,
    formData,
  }: params.ISetAssetGroupCellExcelUpload): AxiosPromise {
    const url = `/asset/group/${id}/excel?project_id=${project_id}`;

    return instance.post(url, formData, {
      timeout: 1000000,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
  getAssetField({ id, project_id }: params.IGetAssetField): AxiosPromise {
    const url = `/assets/${id}?project_id=${project_id}`;

    return instance.get(url);
  },
  getAssetsPage({
    id,
    project_id,
    params,
    size,
    page,
  }: params.IGetAssetField): AxiosPromise {
    const url = `/assetspage/${id}?project_id=${project_id}&col=${
      typeof params !== "undefined" ? params?.col : ""
    }&keyword=${
      typeof params !== "undefined" ? params?.keyword : ""
    }&size=${size}&page=${page}`;

    return instance.get(url);
  },
  setAssetField({
    id,
    project_id,
    size = 100,
    page = 1,
    datas,
  }: params.ISetAssetField): AxiosPromise {
    const url = `/assets/${id}?project_id=${project_id}&size=${size}&page=${page}`;

    return instance.post(url, datas, { timeout: 1000000 });
  },
  delAssetField({
    id,
    project_id,
    datas,
    size = 100,
    page = 1,
  }: params.ISetAssetField): AxiosPromise {
    const url = `/assets/${id}/delete?project_id=${project_id}&size=${size}&page=${page}`;

    return instance.post(url, datas, { timeout: 1000000 });
  },
  setAssetCellDataExcelUpload({
    id,
    project_id,
    formData,
    upDateType,
  }: params.ISetAssetCellDataExcelUpload): AxiosPromise {
    const url = `/assets/${id}/excel?project_id=${project_id}&update_type=${upDateType}`;

    return instance.post(url, formData, {
      timeout: 1000000,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
  getAssetExcelSampleDownload({
    id,
    project_id,
  }: params.IGetAssetExcelSampleDownload): AxiosPromise {
    const url = `/assets/${id}/form?project_id=${project_id}`;
    return instance.get(url, { responseType: "blob" });
  },
  getAssetExcelDownload({
    id,
    project_id,
    date,
  }: params.IGetAssetExcelDownload): AxiosPromise {
    const url = `/assets/${id}/download?project_id=${project_id}&date=${date}`;
    return instance.get(url, { responseType: "blob", timeout: 100000 });
  },
  getAssetsSearch({
    id,
    project_id,
    params,
  }: params.IGetAssetsSearch): AxiosPromise {
    const url = `/assets/${id}?project_id=${project_id}&col=${params.col}&keyword=${params.keyword}`;

    return instance.get(url);
  },

  getCertificationCategory({
    project_id,
  }: params.IGetCertificationCategory): AxiosPromise {
    const url = `/cert/category?project_id=${project_id}`;

    return instance.get(url);
  },
  getCertificationState({
    project_id,
    category_id,
  }: params.IGetCertificationState): AxiosPromise {
    const categoryId =
      category_id !== null ? `&category_id=${category_id}` : "";
    const url = `/cert/state?project_id=${project_id}${categoryId}`;

    return instance.get(url);
  },
  getCertificationDocList({
    project_id,
    keyword,
  }: params.IGetCertificationDocList): AxiosPromise {
    const url = `/cert/doc/search?project_id=${project_id}&keyword=${keyword}`;
    return instance.get(url);
  },
  setCertificationState({
    project_id,
    category_id,
    datas,
  }: params.ISetCertificationState): AxiosPromise {
    const categoryId =
      category_id !== null ? `&category_id=${category_id}` : "";
    const url = `/cert/state?project_id=${project_id}${categoryId}`;

    return instance.post(url, datas);
  },
  delCertificationStateDoc({
    project_id,
    id,
  }: params.IDelCertificationStateDoc): AxiosPromise {
    const url = `/cert/state/doc/${id}?project_id=${project_id}`;
    return instance.delete(url);
  },
  getCertificationTrace({
    project_id,
    category_id,
  }: params.IGetCertificationTrace): AxiosPromise {
    const categoryId =
      category_id === null ? "" : `&category_id=${category_id}`;
    const url = `/cert/trace?project_id=${project_id}${categoryId}`;
    return instance.get(url);
  },
  setCertificationTrace({
    project_id,
    datas,
  }: params.ISetCertificationTrace): AxiosPromise {
    const url = `/cert/trace?project_id=${project_id}`;
    return instance.post(url, datas);
  },
  delCertificationTrace({
    project_id,
    id,
  }: params.IDelCertificationTrace): AxiosPromise {
    const url = `/cert/trace/${id}?project_id=${project_id}`;

    return instance.delete(url);
  },
  getCertificationCategorySearch({
    project_id,
    keyword,
  }: params.IGetCertificationCategorySearch): AxiosPromise {
    const url = `/cert/category/search?project_id=${project_id}&keyword=${keyword}`;
    return instance.get(url);
  },
  getMemberSearch({
    project_id,
    member_name,
  }: params.IGetMemberSearch): AxiosPromise {
    const url = `/members?project_id=${project_id}&member_name=${member_name}`;
    return instance.get(url);
  },
  getCertificationTraceDetail({
    project_id,
    id,
  }: params.IGetCertificationTraceDetail): AxiosPromise {
    const url = `/cert/trace/${id}?project_id=${project_id}`;

    return instance.get(url);
  },
  delCertificationTraceFile({
    project_id,
    file_id,
    id,
  }: params.IDelCertificationTraceFile): AxiosPromise {
    const url = `/cert/trace/${id}/file/${file_id}?project_id=${project_id}`;

    return instance.delete(url);
  },
  setCertificationTraceFileUpload({
    id,
    project_id,
    formData,
  }: params.ISetCertificationTraceFileUpload): AxiosPromise {
    const url = `/cert/trace/${id}?project_id=${project_id}`;

    return instance.post(url, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
  getCertificationTraceFileDownload({
    trace_file,
    project_id,
  }: params.IGetCertificationTraceFileDownload): AxiosPromise {
    const url = `/downloadFile/${trace_file}?project_id=${project_id}`;
    return instance.get(url, { responseType: "blob" });
  },
  getCertificationSearch({
    project_id,
    id,
    params,
  }: params.IGetCertificationSearch): AxiosPromise {
    const url = `/cert/trace?project_id=${project_id}&category_id=${
      id !== null ? id : ""
    }&keyword=${params.keyword}&content=${
      typeof params.content !== "undefined" ? params.content : ""
    }`;

    return instance.get(url);
  },
  getPolicyGroup({ project_id }: params.IGetPolicyGroup): AxiosPromise {
    const url = `/policy/group?project_id=${project_id}`;

    return instance.get(url);
  },
  setPolicyGroup({ project_id, data }: params.ISetPolicyGroup): AxiosPromise {
    const url = `/policy/group?project_id=${project_id}`;

    return instance.post(url, data);
  },
  setPolicyGroupSort({
    project_id,
    data,
  }: params.ISetPolicyGroupSort): AxiosPromise {
    const url = `/policy/group?project_id=${project_id}`;

    return instance.post(url, data);
  },
  deletePolicyGroup({
    project_id,
    id,
  }: params.IDeletePolicyGroup): AxiosPromise {
    const url = `/policy/group/${id}?project_id=${project_id}`;

    return instance.delete(url);
  },
  deletePolicyGroupList({
    project_id,
    id,
  }: params.IDeletePolicyGroupList): AxiosPromise {
    const url = `/policy/doc/${id}?project_id=${project_id}`;

    return instance.delete(url);
  },
  getUsers({ project_id, datas }: params.IGetUsers): AxiosPromise {
    const url = `/users?project_id=${project_id}&user_name=${datas.user_name}&user_id=${datas.user_id}&appr_yn=${datas.appr_yn}`;

    return instance.get(url);
  },
  setUsers(userData: params.ISetUser): AxiosPromise {
    const url = `/user`;

    return instance.post(url, userData);
  },
  setUserProfile(userData: params.ISetUser): AxiosPromise {
    const url = `/user/profile`;

    return instance.post(url, userData);
  },
  deleteUser(username: string): AxiosPromise {
    const url = `/user/${username}`;

    return instance.delete(url);
  },
  resetPassword(userData: params.ISetUser): AxiosPromise {
    const url = `/user/password`;

    return instance.post(url, userData);
  },
  resetTOTPKey(userData: params.ISetUser): AxiosPromise {
    const url = `/user/totpkey`;

    return instance.post(url, userData);
  },
  getProject(project_name: string): AxiosPromise {
    const url = `/projects?project_name=${project_name}`;

    return instance.get(url);
  },
  setProject(datas: IProjectDatas): AxiosPromise {
    const url = "/project";

    return instance.post(url, datas);
  },
  deleteProject(project_id: number): AxiosPromise {
    const url = `/project/${project_id}`;

    return instance.delete(url);
  },
  getCertScore({
    project_id,
    category_id,
    date,
  }: params.IGetCertScore): AxiosPromise {
    const url = `/cert/score?project_id=${project_id}&category_id=${
      category_id === null ? "" : category_id
    }&date=${date || ""}`;

    return instance.get(url, { useCache: false });
  },
  setCertScore({ project_id, datas }: params.ISetCertScore): AxiosPromise {
    const url = `/cert/score?project_id=${project_id}`;

    return instance.post(url, datas);
  },
  setCertScoreProc({ project_id, datas }: params.ISetCertScore): AxiosPromise {
    const url = `/cert/score/proc?project_id=${project_id}`;

    return instance.post(url, datas);
  },
  getCertTech({
    project_id,
    group_id,
    keyword,
  }: params.IGetCertTech): AxiosPromise {
    const url = `/cert/tech?project_id=${project_id}&group_id=${
      group_id === null ? "" : group_id
    }&keyword=${keyword}`;

    return instance.get(url);
  },
  getRiskThreat({ project_id }: params.IGetRiskThreat): AxiosPromise {
    const url = `/risk/threat?project_id=${project_id}`;

    return instance.get(url);
  },
  setRiskThreat({ project_id, datas }: params.ISetRiskResult): AxiosPromise {
    const url = `/risk/threat?project_id=${project_id}`;

    return instance.post(url, datas);
  },
  removeRiskThreat({ project_id, id }: params.IRemoveRiskResult): AxiosPromise {
    const url = `/risk/threat/${id}?project_id=${project_id}`;

    return instance.delete(url);
  },
  getTechManual({
    project_id,
    group_name,
    os,
    asset_id,
    group_id,
  }: params.IGetTechManual): AxiosPromise {
    const url = `/cert/tech/manual?project_id=${project_id}&group_name=${group_name}&os=${os}&asset_id=${asset_id}&group_id=${group_id}`;

    return instance.get(url);
  },
  setTechManualStep1({
    project_id,
    group_name,
    os,
    asset_id,
    datas,
    group_id,
  }: params.IGetTechManualStep): AxiosPromise {
    const url = `/cert/tech/manual/step1?project_id=${project_id}&group_name=${group_name}&os=${os}&asset_id=${asset_id}&group_id=${group_id}`;

    return instance.post(url, datas);
  },
  setTechManualStep2({
    project_id,
    group_name,
    os,
    asset_id,
    datas,
  }: params.IGetTechManualStep): AxiosPromise {
    const url = `/cert/tech/manual/step2?project_id=${project_id}&group_name=${group_name}&os=${os}&asset_id=${asset_id}`;

    return instance.post(url, datas);
  },
  setTechManualProc1({
    project_id,
    group_name,
    os,
    asset_id,
    datas,
    group_id,
  }: params.IGetTechManualStep): AxiosPromise {
    const url = `/cert/tech/manual/proc1?project_id=${project_id}&group_name=${group_name}&os=${os}&asset_id=${asset_id}&group_id=${group_id}`;

    return instance.post(url, datas);
  },
  setTechDelete({
    project_id,
    group_name,
    os,
    asset_id,
    datas,
    group_id,
  }: params.IGetTechManualStep): AxiosPromise {
    const url = `/cert/tech/delete?project_id=${project_id}&group_name=${group_name}&os=${os}&asset_id=${asset_id}&group_id=${group_id}`;

    return instance.post(url, datas);
  },
  setTechManualProc2({
    project_id,
    group_name,
    os,
    asset_id,
    datas,
    group_id,
  }: params.IGetTechManualStep): AxiosPromise {
    const url = `/cert/tech/manual/proc2?project_id=${project_id}&group_name=${group_name}&os=${os}&asset_id=${asset_id}&group_id=${group_id}`;

    return instance.post(url, datas);
  },
  setTechManualProc({
    project_id,
    group_name,
    os,
    asset_id,
    datas,
    group_id,
  }: params.IGetTechManualStep): AxiosPromise {
    const url = `/cert/tech/proc?project_id=${project_id}&group_name=${group_name}&os=${os}&asset_id=${asset_id}&group_id=${group_id}`;

    return instance.post(url, datas);
  },
  getRiskResult({
    project_id,
    threat_code = "",
    threat_desc = "",
    vulner_desc = "",
    risk_value = "",
  }: params.IGetRiskResult): AxiosPromise {
    const url = `/risk/result?project_id=${project_id}&threat_code=${threat_code}&threat_desc=${threat_desc}&vulner_desc=${vulner_desc}&risk_value=${risk_value}`;

    return instance.get(url);
  },
  getAdminLog({
    project_id,
    username,
    ip,
    url,
    from,
    to,
    summary,
    size,
    page,
  }: params.IGetAdminLog): AxiosPromise {
    const apiUrl = `/admin/log?project_id=${project_id}&username=${username}&ip=${ip}&url=${url}&from=${from}&to=${to}&summary=${summary}&size=${size}&page=${page}`;

    return instance.get(apiUrl);
  },
  getAdminLogExcelDownload({
    project_id,
    username,
    ip,
    url,
    from,
    to,
    summary,
  }: params.IGetAdminLog): AxiosPromise {
    const apiUrl = `/admin/log/download?project_id=${project_id}&username=${username}&ip=${ip}&url=${url}&from=${from}&to=${to}&summary=${summary}`;

    return instance.get(apiUrl, { responseType: "blob" });
  },
  getCertStateExcelDownload({
    project_id,
  }: params.IGetCertStateExcelDownload): AxiosPromise {
    const url = `/cert/state/download?project_id=${project_id}`;

    return instance.get(url, { responseType: "blob" });
  },
  getAssetEvalution({
    project_id,
    group_id,
    keyword = "",
    date = "",
  }: params.IGetCertTech): AxiosPromise {
    const url = `/assets/eval/${group_id}?project_id=${project_id}&keyword=${keyword}&date=${date}`;

    return instance.get(url);
  },
  getAssetEvalPage({
    project_id,
    group_id,
    keyword = "",
    date = "",
    size,
    page,
  }: params.IGetCertTech): AxiosPromise {
    const url = `/assets/evalpage/${group_id}?project_id=${project_id}&keyword=${keyword}&date=${date}&size=${size}&page=${page}`;

    return instance.get(url);
  },
  setAssetEvalution({
    project_id,
    group_id,
    datas,
    size,
    page,
  }: params.ISetAssetsEval): AxiosPromise {
    const url = `/assets/eval/${group_id}?project_id=${project_id}&size=${size}&page=${page}`;

    return instance.post(url, datas);
  },
  getRiskChartdata({ project_id }: params.IGetRiskLevel): AxiosPromise {
    const url = `/risk/chart?project_id=${project_id}`;

    return instance.get(url);
  },
  getRiskLevel({ project_id }: params.IGetRiskLevel): AxiosPromise {
    const url = `/risk/level?project_id=${project_id}`;

    return instance.get(url);
  },
  setRiskLevel({ project_id, datas }: params.ISetRiskLevel): AxiosPromise {
    const url = `/risk/level?project_id=${project_id}`;

    return instance.post(url, datas);
  },
  getRiskYm({ project_id }: params.IGetRiskYm): AxiosPromise {
    const url = `/risk/ym?project_id=${project_id}`;

    return instance.get(url);
  },
  getRiskPlan({
    project_id,
    ym,
    category_id,
  }: params.IGetRiskPlan): AxiosPromise {
    const url = `/risk/plan?project_id=${project_id}&ym=${ym}&category_id=${category_id}`;

    return instance.get(url);
  },
  setRiskPlan({
    project_id,
    ym,
    category_id,
    datas,
  }: params.ISetRiskPlan): AxiosPromise {
    const url = `/risk/plan?project_id=${project_id}&ym=${ym}&category_id=${category_id}`;

    return instance.post(url, datas);
  },
  getAdminRole({ project_id, userID }: params.IGetAdminRole): AxiosPromise {
    const url = `/admin/role/${userID}?project_id=${project_id}`;

    return instance.get(url);
  },
  setAdminRole({
    project_id,
    userID,
    datas,
  }: params.ISetAdminRole): AxiosPromise {
    const url = `/admin/role/${userID}?project_id=${project_id}`;

    return instance.post(url, datas);
  },
  deleteAdminRole({
    project_id,
    userID,
    roleID,
  }: params.IDeleteAdminRole): AxiosPromise {
    const url = `/admin/role/${userID}/${roleID}?project_id=${project_id}`;

    return instance.delete(url);
  },
  getAdminCertCategory({
    project_id,
  }: params.IGetAdminCertCategory): AxiosPromise {
    const url = `/admin/cert/category?project_id=${project_id}`;

    return instance.get(url);
  },
  setAdminCertCategory({
    project_id,
    datas,
  }: params.ISetAdminCertCategory): AxiosPromise {
    const url = `/admin/cert/category?project_id=${project_id}`;

    return instance.post(url, datas);
  },
  deleteAdminCertCategory({
    project_id,
    id,
  }: params.IDeleteAdminCertCategory): AxiosPromise {
    const url = `/admin/cert/category/${id}?project_id=${project_id}`;

    return instance.delete(url);
  },
  getAdminCertCategoryCheckList({
    project_id,
    id,
  }: params.IGetAdminCertCategoryCheckList): AxiosPromise {
    const url = `/admin/cert/category/${id}/checklist?project_id=${project_id}`;

    return instance.get(url);
  },
  setAdminCertCategoryCheckList({
    project_id,
    id,
    datas,
  }: params.ISetAdminCertCategoryCheckList): AxiosPromise {
    const url = `/admin/cert/category/${id}/checklist?project_id=${project_id}`;

    return instance.post(url, datas);
  },
  deleteAdminCertCategoryCheckList({
    project_id,
    id,
    check_id,
  }: params.IDeleteAdminCertCategoryCheckList): AxiosPromise {
    const url = `/admin/cert/category/${id}/checklist/${check_id}?project_id=${project_id}`;

    return instance.delete(url);
  },

  getAdminCode({
    projectId,
    id,
  }: params.IGetAdminCodeParam): AxiosPromise<ICodeData[]> {
    return instance.get(`/admin/codes/${id}?project_id=${projectId}`, {
      params,
    });
  },
  setAdminCode({
    projectId,
    cd,
    data,
  }: params.ISetAdminCodeParam): AxiosPromise {
    return instance.post(`/admin/codes/${cd}?project_id=${projectId}`, {
      data,
    });
  },

  deleteAdminCode({
    projectId,
    id,
  }: params.IDeleteAdminCodeParam): AxiosPromise {
    return instance.delete(`/admin/codes/${id}?project_id=${projectId}`);
  },

  getAdminLicense({
    project_name = "",
    license_key = "",
  }: params.IGetAdminLicense): AxiosPromise {
    const url = `/admin/license?project_name=${project_name}&license_key=${license_key}`;

    return instance.get(url);
  },
  setAdminLicense({ datas }: params.ISetAdminLicense): AxiosPromise {
    const url = `/admin/license`;

    return instance.post(url, datas);
  },
  deleteAdminLicense({ id }: params.IDeleteAdminLicense): AxiosPromise {
    const url = `/admin/license/${id}`;

    return instance.delete(url);
  },
  getCompanyList(): AxiosPromise {
    const url = "/admin/license/company";

    return instance.get(url);
  },
  getLicenseCheck({ project_id }: params.IGetLicenseCheck): AxiosPromise {
    const url = `/project/${project_id}`;

    return instance.get(url);
  },
  getPreviewDocs({ filekey }: params.IGetPreviewDocs): AxiosPromise {
    const url = `/previewDocs/${filekey}`;

    return instance.get(url, { responseType: "blob" });
  },
  getAdminConfig(): AxiosPromise {
    const url = `/admin/config`;

    return instance.get(url);
  },
  setAdminConfig({ data }: params.ISetAdminConfig): AxiosPromise {
    const url = `/admin/config`;

    return instance.post(url, data);
  },
  getAdminUpdate({ update }: params.IGetAdminUpdate): AxiosPromise {
    const url = `/admin/update?system=${update}`;

    return instance.post(url, { timeout: 1000000 });
  },
  getDashboardDiagnostic({
    project_id,
  }: params.IGetDashboardDiagnostic): AxiosPromise {
    const url = `/dashboard/diagnostic?project_id=${project_id}`;

    return instance.get(url);
  },
  getDashboarDevidence({
    project_id,
  }: params.IGetDashboarDevidence): AxiosPromise {
    const url = `/dashboard/evidence?project_id=${project_id}`;

    return instance.get(url);
  },
  getDashboarAsset({ project_id }: params.IGetDashboarAsset): AxiosPromise {
    const url = `/dashboard/asset?project_id=${project_id}`;

    return instance.get(url);
  },
  getDashboarAssetDiagnosis({
    project_id,
  }: params.IGetDashboarAssetDiagnosis): AxiosPromise {
    const url = `/dashboard/asset-diagnosis?project_id=${project_id}`;

    return instance.get(url);
  },
  getDashboarAssetEvaluation({
    project_id,
  }: params.IGetDashboarAssetEvaluation): AxiosPromise {
    const url = `/dashboard/asset-evaluation?project_id=${project_id}`;

    return instance.get(url);
  },
  getDashboarVulnerability({
    project_id,
    group_id,
  }: params.IGetDashboarVulnerability): AxiosPromise {
    const url = `/dashboard/vulnerability?project_id=${project_id}&group_id=${group_id}`;

    return instance.get(url);
  },
  getDashboardDiagnosticSub({
    project_id,
  }: params.IGetDashboardDiagnosticSub): AxiosPromise {
    const url = `/dashboard/diagnostic-sub?project_id=${project_id}`;

    return instance.get(url);
  },
  getDashboardDiagnosticSubTech({
    project_id,
  }: params.IGetDashboardDiagnosticSubTech): AxiosPromise {
    const url = `/dashboard/diagnostic-sub-tech?project_id=${project_id}`;

    return instance.get(url);
  },
  getDashboardAlarm({ project_id }: params.IGetDashboardAlarm): AxiosPromise {
    const url = `/dashboard/alarm?project_id=${project_id}`;

    return instance.get(url);
  },
  getCertScoreDates({ project_id }: params.IGetCertScoreDates): AxiosPromise {
    const url = `/cert/score/dates?project_id=${project_id}`;

    return instance.get(url);
  },
  getMemberExcelSampleDownload({
    project_id,
  }: params.IGetMemberExcelSampleDownload): AxiosPromise {
    const url = `/member/form?project_id=${project_id}`;

    return instance.get(url, { responseType: "blob" });
  },
  setMemberExcelUpload({
    project_id,
    formData,
  }: params.ISetMemberExcelUpload): AxiosPromise {
    const url = `/member/excel?project_id=${project_id}`;

    return instance.post(url, formData, {
      timeout: 1000000,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
  getAssetsEvalDate({
    project_id,
    id,
  }: params.IGetAssetsEvalDate): AxiosPromise {
    const url = `/assets/eval/date/${id}?project_id=${project_id}`;

    return instance.get(url);
  },
  getAssetsEvalSampleDownload({
    project_id,
    id,
  }: params.IGetAssetsEvalSampleDownload): AxiosPromise {
    const url = `/assets/eval/${id}/form?project_id=${project_id}`;

    return instance.get(url, { responseType: "blob" });
  },
  setAssetsEvalExcelDownload({
    project_id,
    id,
    formData,
  }: params.ISetAssetsEvalExcelDownload): AxiosPromise {
    const url = `/assets/eval/${id}/excel?project_id=${project_id}`;

    return instance.post(url, formData, {
      timeout: 1000000,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
  setAssetGroupFromSampleDownload({
    id,
    project_id,
  }: params.IGetAssetExcelSampleDownload): AxiosPromise {
    const url = `/asset/group/${id}/form?project_id=${project_id}`;

    return instance.get(url, { responseType: "blob" });
  },
  getCertScoreDownload({
    project_id,
    category_id,
    date,
  }: params.IGetCertScoreDownload): AxiosPromise {
    const url = `/cert/score/download?project_id=${project_id}&category_id=${
      category_id === null ? "" : category_id
    }&date=${date || ""}`;

    return instance.get(url, { responseType: "blob" });
  },

  setCertTechUpload({
    formData,
    project_id,
    group_name,
    group_id,
    asset_id,
  }: params.ISetCertTechUpload): AxiosPromise {
    const url = `/cert/tech/upload?project_id=${project_id}&group_name=${group_name}&group_id=${group_id}${
      asset_id === 0 ? "" : "&asset_id=" + asset_id
    }`;

    return instance.post(url, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
  getAdminVersion(): AxiosPromise {
    const url = `/admin/version`;

    return instance.get(url);
  },
  setAdminVersion({ datas }: params.ISetAdminVersion): AxiosPromise {
    const url = `/admin/version`;

    return instance.post(url, datas);
  },
  deleteAdminVersion(id: params.IDeleteAdminVersion): AxiosPromise {
    const url = `/admin/version/${id}`;

    return instance.delete(url);
  },
  getCertDocSearch({
    project_id,
    keyword,
  }: params.IGetCertDocSearch): AxiosPromise {
    const url = `/cert/doc/search?project_id=${project_id}&keyword=${keyword}`;

    return instance.get(url);
  },
  getAdminMenuMy({ project_id }: params.IGetAdminMenuMy): AxiosPromise {
    const url = `/admin/menu/my?project_id=${project_id}`;

    return instance.get(url);
  },
  getAdminUserMenu({ project_id, id }: params.IGetAdminUserMenu): AxiosPromise {
    const url = `/admin/menu/${id}?project_id=${project_id}`;

    return instance.get(url);
  },
  setAdminUserMenu({
    project_id,
    id,
    datas,
  }: params.ISetAdminUserMenu): AxiosPromise {
    const url = `/admin/menu/${id}?project_id=${project_id}`;

    return instance.post(url, datas);
  },
  setCertScoreUpdate({
    project_id,
    date,
    datas,
  }: params.ISetCertScoreUpdate): AxiosPromise {
    const url = `/cert/score/update?project_id=${project_id}&date=${date}`;

    return instance.post(url, datas);
  },
  getCertStateDoc({
    doc_id,
    project_id,
  }: params.IGetCertStateDoc): AxiosPromise {
    const url = `/cert/state/doc/${doc_id}?project_id=${project_id}`;

    return instance.get(url);
  },
  getDashboardConfig({ project_id }: params.IGetDashboardConfig): AxiosPromise {
    const url = `/dashboard/config?project_id=${project_id}`;

    return instance.get(url);
  },
  getCertTechDownload({
    project_id,
    type,
  }: params.IGetCertTechDownload): AxiosPromise {
    const url = `/cert/tech/download?project_id=${project_id}&t=${type}`;

    return instance.get(url, { responseType: "arraybuffer" });
  },
  getRiskTechResult({
    project_id,
    group_id,
    col = "",
    keyword = "",
    check_code = "",
    check_desc = "",
    risk_value = "",
    size,
    page,
  }: params.IGetRiskTechResult): AxiosPromise {
    const url = `/risk/techresult/${group_id}?project_id=${project_id}&col=${col}&keyword=${keyword}&check_code=${check_code}&check_desc=${check_desc}&risk_value=${risk_value}&size=${size}&page=${page}`;

    return instance.get(url);
  },
  getRiskTechPlan({
    project_id,
    ym = "",
    col = "",
    keyword = "",
    asset_group = "",
    check_code = "",
    risk_value = "",
    size,
    page,
    status = "",
  }: params.IGetRiskTechPlan): AxiosPromise {
    const url = `/risk/techplan?project_id=${project_id}&status=${status}&ym=${ym}&col=${col}&keyword=${keyword}&asset_group=${asset_group}&check_code=${check_code}&risk_value=${risk_value}&size=${size}&page=${page}`;

    return instance.get(url);
  },
  setRiskTechPlan({
    project_id,
    ym,
    size,
    page,
    datas,
  }: params.ISetRiskTechPlan): AxiosPromise {
    const url = `/risk/techplan?project_id=${project_id}&ym=${ym}&size=${size}&page=${page}`;

    return instance.post(url, datas);
  },
  getTechEvaluationExcelDownload({
    project_id,
    group_id,
    col = "",
    keyword = "",
    check_code = "",
    check_desc = "",
    risk_value = "",
  }: params.IGetTechEvaluationExcelDownload): AxiosPromise {
    const url = `/risk/techresult/${group_id}/download?project_id=${project_id}&col=${col}&keyword=${keyword}&check_code=${check_code}&check_desc=${check_desc}&risk_value=${risk_value}&size=10000&page=1`;

    return instance.get(url, { responseType: "blob" });
  },
  getTechProtectionExcelDownload({
    project_id,
    ym = "",
    col = "",
    keyword = "",
    check_code = "",
    risk_value = "",
    status = "",
  }: params.IGetTechProtectionExcelDownload): AxiosPromise {
    const url = `/risk/techplan/download?project_id=${project_id}&status=${status}&ym=${ym}&col=${col}&keyword=${keyword}&check_code=${check_code}&risk_value=${risk_value}&size=10000&page=1`;

    return instance.get(url, { responseType: "blob" });
  },
  getCertTechPage({
    project_id,
    group_id = "",
    keyword,
    size,
    page,
  }: params.IGetCertTechPage): AxiosPromise {
    const url = `/cert/techpage?project_id=${project_id}&group_id=${group_id}&keyword=${keyword}&size=${size}&page=${page}`;

    return instance.get(url);
  },
  setTechManualCloseProc({
    project_id,
    group_name,
    os,
    asset_id,
    datas,
    group_id,
  }: params.IGetTechManualStep): AxiosPromise {
    const url = `/cert/tech/close?project_id=${project_id}&group_name=${group_name}&os=${os}&asset_id=${asset_id}&group_id=${group_id}`;

    return instance.post(url, datas);
  },
  getRiskYmLevel({ project_id }: params.IGetRiskYmLevel): AxiosPromise {
    const url = `/risk/ym/level?project_id=${project_id}`;

    return instance.get(url);
  },
  removeRiskYmLevel({
    project_id,
    ym,
  }: params.IRemoveRiskYmLevel): AxiosPromise {
    const url = `/risk/ym/${ym}?project_id=${project_id}`;

    return instance.delete(url);
  },
  getRiskAnal({ project_id }: params.IGetRiskAnal): AxiosPromise {
    const url = `/risk/anal?project_id=${project_id}`;

    return instance.get(url);
  },
  setRiskAnal({ project_id, datas }: params.ISetRiskAnal): AxiosPromise {
    const url = `/risk/anal?project_id=${project_id}`;

    return instance.post(url, datas);
  },
  removeRiskAnal({ project_id, id }: params.IRemoveRiskAnal): AxiosPromise {
    const url = `/risk/anal/${id}?project_id=${project_id}`;

    return instance.delete(url);
  },
};
