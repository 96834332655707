import "moment/locale/ko";

import { Button, DatePicker, Form, Input, Space } from "antd";
import React, { useContext, useState } from "react";

import { IGetAdminLog } from "@/api/type";
import { ProjectContext } from "@/common/project-provider";
import dayjs from "dayjs";

import styled from "styled-components";
import { useTranslation } from "react-i18next";

const { RangePicker } = DatePicker;

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IDesktopAdminLogHeaderProps {
  setParams: React.Dispatch<React.SetStateAction<IGetAdminLog>>;
}

const Container = styled.div`
  text-align: left;
  padding: 20px;
  border: 1px solid ${(props) => props.theme.layout.searchBorderColor};
  box-sizing: border-box;
  border-radius: 10px;
  .ant-form-item {
    margin: 0;
  }
  .ant-picker {
    border: 1px solid ${(props) => props.theme.layout.searchBorderColor};
  }
`;
const Label = styled.label`
  font-size: 15px;
  margin-left: 15px;
`;

const DesktopAdminLogHeader: React.FunctionComponent<
  IDesktopAdminLogHeaderProps
> = ({ setParams }) => {
  const { t } = useTranslation();
  const { projectId } = useContext(ProjectContext);
  const onFinish = (values: any) => {
    //console.log(values);

    const { userID, ip, url, summary, date1 } = values;
    const params: IGetAdminLog = {
      project_id: projectId,
      username: userID || "",
      ip: ip || "",
      url: url || "",
      summary: summary || "",
      size: 10,
      page: 1,
      to: typeof date1 !== "undefined" ? date1[1].format("YYYY-MM-DD") : "",
      from: typeof date1 !== "undefined" ? date1[0].format("YYYY-MM-DD") : "",
    };

    setParams(params);
  };

  const [form] = Form.useForm();

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  const resetHandler = () => {
    form.resetFields();
  };

  const [dates, setDates] = useState([]);
  const [tmpDates, setTmpDates] = useState([]);
  const [hackValue, setHackValue] = useState();
  const [value, setValue] = useState();
  const disabledDate = (current) => {
    if (!dates || dates.length === 0) {
      return false;
    }
    const tooLate = dates[0] && current.diff(dates[0], "months") > 1;
    const tooEarly = dates[1] && dates[1].diff(current, "months") > 1;
    return tooEarly || tooLate;
  };

  const onChange = (val) => {
    setValue(val);
    console.log("onChange " + val);
  };

  const onOpenChange = (open) => {
    if (open) {
      tmpDates[0] = dates[0];
      tmpDates[1] = dates[1];

      setTmpDates(tmpDates);
      dates[0] = null;
      dates[1] = null;
      setDates(dates);
    } else {
      if (dates[0] == null && dates[1] == null) {
        //console.log("close tmpDates " + tmpDates);
        dates[0] = tmpDates[0];
        dates[1] = tmpDates[1];
        setDates(dates);
        setHackValue(tmpDates[0]);
      }
    }
  };

  return (
    <Container>
      <Form
        name="basic"
        initialValues={{ remember: false }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        form={form}
      >
        <Space>
          <Label>{t("기간")}</Label>
          <Form.Item
            name="date1"
            initialValue={[dayjs().subtract(1, "months"), dayjs()]}
          >
            <RangePicker
              format="YYYY-MM-DD"
              value={hackValue || value}
              disabledDate={disabledDate}
              onCalendarChange={(val) => setDates(val)}
              onChange={onChange}
              onOpenChange={onOpenChange}
            />
          </Form.Item>
          <Label>ID</Label>
          <Form.Item name="userID">
            <Input style={{ width: 100 }} />
          </Form.Item>
          <Label>IP</Label>
          <Form.Item name="ip">
            <Input style={{ width: 120 }} />
          </Form.Item>
          <Label>URL</Label>
          <Form.Item name="url">
            <Input style={{ width: 120 }} />
          </Form.Item>
          <Label>{t("메뉴")}</Label>
          <Form.Item name="summary">
            <Input style={{ width: 140 }} />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit">
              {t("조회")}
            </Button>
          </Form.Item>
          <Form.Item>
            <Button onClick={resetHandler}>{t("초기화")}</Button>
          </Form.Item>
        </Space>
      </Form>
    </Container>
  );
};

export default DesktopAdminLogHeader;
