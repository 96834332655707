import { Button, Descriptions, Form, Radio, Input, Typography } from 'antd';
import { FieldData, ISystemInfo } from './type';
import React, { useCallback } from 'react';

import { IAdminMenuPermissionProps } from '@/common/type';
import api from '@/api/api';
import { openNotification } from '@/common/util';
import { useTranslation } from 'react-i18next';
const { Text } = Typography;

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IDesktopSystemSetupExternalProps {
  systemInfo: ISystemInfo;
  setSystemInfo: React.Dispatch<React.SetStateAction<ISystemInfo>>;
  pageInfo: IAdminMenuPermissionProps;
  fields: FieldData[];
}

const setAdminConfig = async ({ data }) => {
  const result = await api.setAdminConfig({ data });

  return result.data;
};

const DesktopSystemSetupExternal: React.FunctionComponent<
IDesktopSystemSetupExternalProps
> = ({ systemInfo, setSystemInfo, pageInfo, fields }) => {
  const { t } = useTranslation();
  const onFinish = useCallback(
    (values: any) => {
      if (systemInfo !== null) {
        setAdminConfig({ data: { ...systemInfo, ...values } }).then(() => {
          const notiText = {
            description: t("완료"),
          };

          openNotification(notiText);
        });
      }
    },
    [systemInfo, t]
  );

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <Descriptions bordered>
      <Descriptions.Item label={t("SLACK 연동")} span={4}>
        <Radio.Group
          defaultValue={systemInfo.slack_yn}
          buttonStyle="solid"
          onChange={(event) => {
            setSystemInfo((prev) => ({
              ...prev,
              slack_yn: event.target.value,
            }));
          }}
        >
          <Radio.Button value={true}>{t("사용")}</Radio.Button>
          <Radio.Button value={false}>{t("미사용")}</Radio.Button>
        </Radio.Group>
        <Text style={{ paddingLeft: 10 }}>
          * {t("SLACK 채널에 연동합니다.")}
        </Text>
      </Descriptions.Item>
      <Descriptions.Item label={t("SLACK")} span={3}>
        <Form
          fields={fields}
          name="basic"
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 12 }}
          initialValues={{ remember: false }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            label={t("TOKEN")}
            name="slack_token"
            rules={[
              {
                required: false,
                message: t('SLACK TOKEN을 입력해 주세요.'),
              },
            ]}
          >
            <Input.Password readOnly={!pageInfo.perm_write} />
          </Form.Item>
          <Form.Item
            label={t("Channel ID")}
            name="slack_channel_id"
            rules={[
              {
                required: true,
                message: t('SLACK Channel ID를 입력해 주세요.'),
              },
            ]}
          >
            <Input readOnly={!pageInfo.perm_write} />
          </Form.Item>
          

          {pageInfo.perm_write && (
            <Form.Item wrapperCol={{ offset: 6, span: 16 }}>
              <Button type="primary" htmlType="submit">
                {t("저장")}
              </Button>
            </Form.Item>
          )}
        </Form>
      </Descriptions.Item>
    </Descriptions>
  );
};

export default DesktopSystemSetupExternal;
