import { AutoComplete, Button, Popconfirm } from 'antd';
import { CloseOutlined, PlusSquareOutlined } from '@ant-design/icons';
import React, { useCallback, useContext, useState } from 'react';

import { IAdminMenuPermissionProps } from '@/common/type';
import { IEvaluationDataProps } from './type';
import { ProjectContext } from '@/common/project-provider';
import api from '@/api/api';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IRiskPlanAppr {
  datas: IEvaluationDataProps;
  dataSync: (data) => void;
  pageInfo: IAdminMenuPermissionProps;
  setIsEdit;
}

const Container = styled.div`
  text-align: left;
`;
const AutoCompleteContainer = styled.div`
  padding: 0 25px 5px 10px;
  position: relative;
`;
const StyledDeleteButton = styled(Button)`
  position: absolute;
  right: 0;
  top: 3px;
  .anticon-close {
    /* font-size: 10px; */
    color: #bbb;
  }
`;
const StyledAutoComplete = styled(AutoComplete)``;
const TitleText = styled.h3`
  &:before {
    content: '- ';
  }
`;



const getMemberSearch = async ({ project_id, member_name }) => {
  const result = await api.getMemberSearch({
    project_id,
    member_name,
});

return result.data;
};

const DesktopRiskPlanAppr: React.FunctionComponent<
IRiskPlanAppr
> = ({ datas, setIsEdit, dataSync, pageInfo }) => {
  const { t } = useTranslation();
  let key = 0;
  const { projectId } = useContext(ProjectContext);
  const [appr, setAppr] = useState([...datas.appr]);

  const [options, setOptions] = useState<{ value: string; key: number }[]>([]);

  const onSearch = useCallback(
    (searchText: string) => {
        
        getMemberSearch({ 
            project_id: projectId,
            member_name: searchText,
        }).then(datas => {

          const optionList = datas.map((item, index) => ({
            value: item.member_name,
            key: index,
            id: item.id,
        }));
    
        
        setOptions(!searchText ? [] : optionList);
      });

    },
    [projectId]
  );

  const onSelect = useCallback(
    (selectText: string, selectOption, selectIndex) => {
      setAppr(prev => {
        return prev.map((item, index) => {
          if (index === selectIndex) {
            return { ...item, appr_name: selectText, appr_id: selectOption.id };
          }
          return item;
        });
      });
    },
    []
  );

  const onBlur = useCallback(() => {
    const paramsDatas = { ...datas, appr };

    
    dataSync([paramsDatas]);
    setIsEdit(true);
  }, [dataSync, setIsEdit, datas, appr]);

  
  const addDocsHandler = useCallback(() => {
    setOptions([]);
    setAppr(prev => [
      ...prev,
      {
        key: key++,
        id: null,
        plan_id: null,
        appr_id: null,
        appr_name: ''
      },
    ]);
  }, [key]);


  const removeDocsHandler = useCallback(
    selectIndex => {
      const setsFilter = appr.filter((_, index) => index !== selectIndex);
      const paramsDatas = { ...datas, appr: setsFilter };

      console.log(appr);
      setOptions([]);
      setAppr(setsFilter);
      dataSync([paramsDatas]);
      
      setIsEdit(true);
    },
    [dataSync, setIsEdit, datas, appr]
  );

  return (
    <Container>
      {appr.length > 0 &&
        appr.map((data, index) => (
          <AutoCompleteContainer key={data.id} title={data.appr_name}>
            {pageInfo.perm_write ? (
              <StyledAutoComplete
                options={options}
                style={{
                  width: '100%',
                  fontSize: '12px',
                  marginBottom: '5px',
                }}
                onBlur={onBlur}
                onSearch={onSearch}
                onSelect={(value, option) => {
                  onSelect(value, option, index);
                }}
                placeholder={t("승인")}
                defaultValue={data.appr_name}
                // size='small'
              />
            ) : (
              <TitleText>{data.appr_name}</TitleText>
            )}

            {pageInfo.perm_write && (
              <Popconfirm
                title={t("삭제 하시겠습니까?")}
                onConfirm={() => {
                  removeDocsHandler(index);
                }}
                // onCancel={cancel}
                okText={t("삭제")}
                cancelText={t("취소")}
              >
                <StyledDeleteButton
                  type="text"
                  size="small"
                  icon={<CloseOutlined />}
                ></StyledDeleteButton>
              </Popconfirm>
            )}
          </AutoCompleteContainer>
        ))}
      {pageInfo.perm_write && (
        <Button
          size="small"
          type="text"
          icon={<PlusSquareOutlined />}
          onClick={addDocsHandler} 
          style={{ fontSize: '12px', fontWeight: 'bold' }}
        >
          {t("승인자추가")}
        </Button>
      )}
    </Container>
  );
};

export default DesktopRiskPlanAppr;
