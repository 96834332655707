import { Button, Col, Form, Input, Row, Select, Space } from 'antd';
import { ICodeData } from '@/common/type';
import { IOrgMemberData, IRoleData } from '../type';
import React, { useCallback, useContext, useMemo, useState } from 'react';

import DesktopOrgMembersManagerModalProfile from './desktop-org-members-manager-modal-profile';
import DesktopOrgMembersManagerModalTable from './desktop-org-members-manager-modal-table';
import IMask from 'imask';
import { MaskedInput } from 'antd-mask-input';
import { ProjectContext } from '@/common/project-provider';
import api from '@/api/api';
import { openNotification } from '@/common/util';
import styled from 'styled-components';
import Modal from '@/components/modal';
import { useTranslation } from 'react-i18next';

const { Option } = Select;

interface IDesktopOrgMembersManagerModal {
    visible: boolean;
    setVisible: React.Dispatch<React.SetStateAction<boolean>>;
    datas: IOrgMemberData;
    isNewMember: boolean;
    orgId: number | null;
    codeList: ICodeData[];
    pageName: string;
    orgArrList: IRoleData[];
    fetch?: (param: any) => void;
    pagination?: any;
    currentPage?: number;
}

const Container = styled.div``;

const StyledFormItem = styled(Form.Item)`
    width: 90%;
    #basic_member_hp.ant-input {
        background: ${props => props.theme.layout.sideBg};
        color: ${props => props.theme.font.color};
        border: 1px solid ${props => props.theme.layout.tabBorderColor};
        &::placeholder {
            color: ${props => props.theme.font.color};
        }
    }
`;

interface IFormItemLabel {
    required: boolean;
}
const FormItemLabel = styled.strong<IFormItemLabel>`
    font-size: 13px;
    padding-bottom: 5px;
    display: block;
    position: relative;
    &:after {
        ${props =>
            props.required
                ? `
      content: '*';
      color: red;
      position: relative;
      top: -3px;
      right: -3px;
    `
                : ''}
    }
`;
const StyledInput = styled(Input)`
    font-size: 13px;
`;

const ButtonArea = styled.div`
    text-align: center;
    padding: 20px 0 0 0;
    width: 100%;
`;

const RemoveButton = styled.div`
    text-align: right;
    /* margin-top: -35px; */
`;

const MemberInfo = styled.div`
    padding: 10px 10px 0 15px;
    border-radius: 5px;
    margin-bottom: 10px;
`;

const MemberOrg = styled.div`
    padding: 0 0 0 15px;
    border-radius: 5px;
    margin-bottom: 10px;
`;
const Group1 = styled.div`
    float: right;
`;
const Group2 = styled.div`
    float: left;
`;
const StyledForm = styled(Form)`
    position: relative;
    padding-left: 200px;
    .dark & {
        padding-left: 200px;
    }
    .ant-form-item {
        margin-bottom: 20px;
    }
    .dark .ant-select:not(.ant-select-customize-input) .ant-select-selector & {
        background: transparent;
        border: 1px solid #434343;
    }
`;
const ProfileContainer = styled.div`
    position: absolute;
    left: 0;
    top: 15px;
`;
const DivLine = styled.div`
    height: 1px;
    background: ${props => props.theme.layout.tabBorderColor};
    margin-bottom: 30px;
    margin-top: 10px;
`;

const StyledMaskedInput = styled(MaskedInput)`
    border-radius: 3px;
    &:focus,
    &:active {
        border: 1px solid #6887ad !important;
        box-shadow: 0 0 0 2px rgb(11 55 83 / 18%);
        border-inline-end-width: 1px;
        outline: 0;
    }
    &:hover {
        border: 1px solid #6887ad !important;
        border-inline-end-width: 1px;
    }
`;

const DescriptionItem = ({ title, name, required, message }) => (
    <div>
        <FormItemLabel required={required}>{title}</FormItemLabel>
        <StyledFormItem name={name} rules={[{ required, message }]}>
            <StyledInput size="default" />
        </StyledFormItem>
    </div>
);

const setMember = async ({ projectId, data, pageName }) => {
    const result = await api.setMember({
        project_id: projectId,
        data,
        pageName,
    });

    return result.data;
};

const deleteMember = async ({ id }) => {
    const result = await api.deleteMember({ id });

    return result.data;
};

const DesktopOrgMembersManagerModal: React.FunctionComponent<
    IDesktopOrgMembersManagerModal
> = ({
    visible,
    setVisible,
    datas,
    isNewMember,
    orgId,
    codeList,
    pageName,
    orgArrList,
    fetch,
    pagination,
    currentPage,
}) => {
    const { t } = useTranslation();
    
    const [imageURL, setImageURL] = useState('');

    const { projectId } = useContext(ProjectContext);
    const member = useMemo(() => {
        return Object.keys(datas).map(item => ({
            name: [item],
            value: datas[item],
        }));
    }, [datas]);

    const onFinish = useCallback(
        (value, { id, member_pic, organ_id }) => {
            const data = {
                ...value,
                id: id === null ? null : id,
                member_pic: imageURL !== '' ? imageURL : member_pic,
                project_id: projectId,
                organ_id: orgId === null ? organ_id : orgId,
                member_hp: value.member_hp.replace(/[^0-9]/g, ''),
            };

            setMember({
                projectId,
                data,
                pageName,
            }).then(() => {
                const notiText = {
                    description: t("구성원 추가 및 수정 완료"),
                };
                //console.log(pageName);

                if (pageName !== 'managerList') {
                    fetch({
                        pagination: { ...pagination, current: currentPage },
                    });
                }
                openNotification(notiText);
                setVisible(false);
            });
        },
        [
            imageURL,
            projectId,
            orgId,
            pageName,
            fetch,
            pagination,
            currentPage,
            setVisible,
            t,
        ]
    );

    return (
        <Container>
            <Modal
                title={isNewMember ? t('구성원 추가') : t('구성원 수정')}
                open={visible}
                width={700}
                onCancel={() => {
                    setVisible(false);
                }}
                footer={null}
            >
                <StyledForm
                    name="basic"
                    initialValues={{ remember: true }}
                    fields={member}
                    onFinish={value => {
                        onFinish(value, datas);
                    }}
                >
                    <ProfileContainer>
                        <DesktopOrgMembersManagerModalProfile
                            datas={datas}
                            setImageURL={setImageURL}
                        />
                    </ProfileContainer>
                    <MemberInfo>
                        <Row>
                            <Col span={10}>
                                <Form.Item label="id" name="id" hidden>
                                    <Input />
                                </Form.Item>
                                <DescriptionItem
                                    title={t("이름")}
                                    name="member_name"
                                    required={true}
                                    message={t("이름을 입력해주세요.")}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col span={12}>
                                <FormItemLabel>{t('연락처')}</FormItemLabel>
                                <StyledFormItem
                                    name="member_hp"
                                    rules={[
                                        {
                                            required: false,
                                            message: t('연락처를 입력해주세요.'),
                                        },
                                    ]}
                                >
                                    <StyledMaskedInput
                                        placeholder={t("숫자만 입력해주세요.")}
                                        mask={[
                                            {
                                                mask: '000-0000-0000',
                                                blocks: {
                                                    RGB: {
                                                        mask: IMask.MaskedRange,
                                                        from: 0,
                                                        to: 9999,
                                                    },
                                                },
                                            },
                                        ]}
                                    />
                                </StyledFormItem>
                            </Col>
                            <Col span={12}>
                                <DescriptionItem
                                    title={t("이메일")}
                                    name="member_email"
                                    required={false}
                                    message={t("이메일을 입력해주세요.")}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col span={12}>
                                <FormItemLabel required={true}>
                                {t("직급")}
                                </FormItemLabel>
                                <StyledFormItem
                                    name="rank_cd"
                                    rules={[
                                        {
                                            required: true,
                                            message: t('직급을 선택해주세요.'),
                                        },
                                    ]}
                                >
                                    <Select>
                                        {codeList.length > 0 &&
                                            codeList.map(item => (
                                                <Option
                                                    key={item.item_cd}
                                                    value={item.item_cd}
                                                >
                                                    {item.item_name}
                                                </Option>
                                            ))}
                                    </Select>
                                </StyledFormItem>
                            </Col>
                            <Col span={12}>
                                <DescriptionItem
                                    title={t("담당업무")}
                                    name="member_roll"
                                    required={false}
                                    message={t("업무를 입력해주세요")}
                                />
                            </Col>
                        </Row>
                        <DivLine />
                    </MemberInfo>

                    <MemberOrg>
                        <h3>{t("조직명")} / {t("역할")}</h3>
                        <DesktopOrgMembersManagerModalTable
                            id={datas.id}
                            datas={datas}
                            orgArrList={orgArrList}
                        />
                    </MemberOrg>

                    <Row>
                        <ButtonArea>
                            <Group1>
                                <Form.Item>
                                    <Space size={[8, 16]} wrap>
                                        <Button
                                            type="default"
                                            size="middle"
                                            onClick={() => {
                                                setVisible(false);
                                            }}
                                        >
                                            {t("취소")}
                                        </Button>
                                        <Button
                                            type="primary"
                                            size="middle"
                                            htmlType="submit"
                                        >
                                            {t("저장")}
                                        </Button>
                                    </Space>
                                </Form.Item>
                            </Group1>
                            <Group2>
                                {datas.id !== null && (
                                    <RemoveButton>
                                        <Button
                                            danger
                                            type="default"
                                            size="middle"
                                            onClick={() => {
                                                try {
                                                    if (
                                                        window.confirm(
                                                            t('구성원을 삭제 하시겠습니까?')
                                                        )
                                                    ) {
                                                        deleteMember({
                                                            id: datas.id,
                                                        }).then(() => {
                                                            const notiText = {
                                                                description: t("완료"),
                                                            };

                                                            openNotification(
                                                                notiText
                                                            );
                                                            setVisible(false);
                                                        });
                                                    }
                                                } catch (error) {
                                                    console.log(error);
                                                }
                                            }}
                                        >
                                            {t("삭제")}
                                        </Button>
                                    </RemoveButton>
                                )}
                            </Group2>
                        </ButtonArea>
                    </Row>
                </StyledForm>
            </Modal>
        </Container>
    );
};

export default DesktopOrgMembersManagerModal;
