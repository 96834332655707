import { Button, Select, Space } from 'antd';
import { IEvaluationColumsProps, IEvaluationDataProps } from './type';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { DownloadOutlined } from '@ant-design/icons';
import { IAdminMenuPermissionProps } from '@/common/type';
import { ProjectContext } from '@/common/project-provider';
import api from '@/api/api';
import { openNotification } from '@/common/util';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Excel } from "antd-table-saveas-excel";
import { useTranslation } from 'react-i18next';

const { Option } = Select;

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IDesktopDangersProtectionHeaderProps {
    riskData: IEvaluationDataProps[];
    columns: IEvaluationColumsProps[];
    setCategoryId: React.Dispatch<React.SetStateAction<number | null>>;
    setStandardDateSelect: React.Dispatch<React.SetStateAction<string>>;
    standardDateSelect: string;
    categoryId: number;
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
    isEdit: boolean;
    pageInfo: IAdminMenuPermissionProps;
}

const CertificationSelect = styled.div`
    position: relative;
    display: inline-block;
    z-index: 1;
    float: left;
    margin-bottom: 20px;
`;
const DownloadButton = styled(Button)`
    a {
        display: inline-block;
        padding-left: 10px;
    }
`;
const ButtonArea = styled.div`
    position: sticky;
    top: 70px;
    text-align: right;
    z-index: 6;
    display: inline-block;
    float: right;
    &.fixed {
    }
`;

const getCertificationCategory = async ({ projectId }) => {
    const result = await api.getCertificationCategory({
        project_id: projectId,
    });

    return result.data;
};

const getRiskYm = async ({ projectId }) => {
    const result = await api.getRiskYm({
        project_id: projectId,
    });

    return result.data;
};

const setRiskPlan = async ({ projectId, ym, category_id, datas }) => {
    const result = await api.setRiskPlan({
        project_id: projectId,
        ym,
        category_id,
        datas,
    });

    return result.data;
};

const DesktopDangersProtectionHeader: React.FunctionComponent<
    IDesktopDangersProtectionHeaderProps
> = ({
    riskData,
    setCategoryId,
    setStandardDateSelect,
    standardDateSelect,
    categoryId,
    setIsLoading,
    isEdit,
    pageInfo,
}) => {
    const { t } = useTranslation();
    const { projectId } = useContext(ProjectContext);
    const [category, setCategory] = useState([]);
    const [standardDateList, setStandardDateList] = useState([]);
    const [isSaveLoading, setIsSaveLoading] = useState(false);

    useEffect(() => {
        getRiskYm({ projectId }).then(date => {
            setStandardDateList(date);
            setStandardDateSelect(date[0]);
        });
        getCertificationCategory({ projectId }).then(data => {
            setCategory(data);
        });
    }, [projectId, setStandardDateSelect, t]);

    const handleChange = value => {
        setCategoryId(value);
    };
    const handleDateChange = value => {
        setIsLoading(true);
        setStandardDateSelect(value);
    };

    const handlerProtectionSave = useCallback(() => {
        setIsSaveLoading(true);
        setRiskPlan({
            projectId,
            ym: standardDateSelect,
            category_id: categoryId !== null ? categoryId : '',
            datas: riskData,
        }).then(() => {
            const notiText = {
                description: t("완료"),
            };

            openNotification(notiText);
            setIsSaveLoading(false);
        });
    }, [projectId, standardDateSelect, categoryId, riskData, t]);


    const excelColumns = [

        {
            title: t("통제번호"),
            label: t("통제번호"),
            dataIndex: "check_num",
            key: "check_num",
          },
          {
            title: t("항목"),
            label: t("항목"),
            dataIndex: "category_group",
            key: "category_group",
          },
          {
            title: t("위험내용"),
            label: t("위험내용"),
            dataIndex: "threat_desc",
            key: "threat_desc",
          },
          {
            title: t("취약점"),
            label: t("취약점"),
            dataIndex: "vulner_desc",
            key: "vulner_desc",
          },
          {
            title: t("개선방향"),
            label: t("개선방향"),
            dataIndex: "action_desc",
            key: "action_desc",
          },
          {
            title: t("위험도"),
            label: t("위험도"),
            dataIndex: "risk_value",
            key: "risk_value",
          },
    
          {
            title: t("시급성"),
            label: t("시급성"),
            dataIndex: "urgency",
            key: "urgency",
          },
          {
            title: t("구현비용"),
            label: t("구현비용"),
            dataIndex: "cost",
            key: "cost",
          },
          {
            title: t("구현난이도"),
            label: t("구현난이도"),
            dataIndex: "difficulty",
            key: "difficulty",
          },
          {
            title: t("우선순위"),
            label: t("우선순위"),
            dataIndex: "priority",
            key: "priority",
          },
          {
            title: t("조치현황"),
            label: t("조치현황"),
            dataIndex: "status",
            key: "status",
          },
          {
            title: t("조치일정"),
            label: t("조치일정"),
            dataIndex: "close_date",
            key: "close_date",
            },
          {
            title: t("비고"),
            label: t("비고"),
            dataIndex: "remark",
            key: "remark",
          },
          {
            title: t("담당자"),
            label: t("담당자"),
            dataIndex: "member_name",
            key: "member_name",
          },
          {
            title: t("Owner"),
            label: t("Owner"),
            dataIndex: "str_owner",
            key: "str_owner",
          },
          {
            title: t("승인"),
            label: t("승인"),
            dataIndex: "str_appr",
            key: "str_appr",
          },
        ];

        const handleExcelDownload = () => {

            const excelData = riskData;
            

            for (let i = 0; i< excelData.length; i++){
                const owner = excelData[i].owner;
                let strOwner = "";
                let strAppr = "";

                for (let j = 0; j< owner.length; j++){
                    strOwner = strOwner + owner[j].owner_name + "\n";
                }
                const appr = excelData[i].appr;
                for (let j = 0; j< appr.length; j++){
                    strAppr = strAppr + appr[j].appr_name + "\n";
                }
                excelData[i].str_owner = strOwner;
                excelData[i].str_appr = strAppr;
            }

            const excel = new Excel();
        excel
          .addSheet("Sheet1")
          .addColumns(excelColumns)
          .addDataSource(excelData, {
            str2Percent: true
          })
          .saveAs(`Information_protection_plan.xlsx`);
      };


    return (
        <>
            <CertificationSelect>
                <Space>
                    <span>{t("통제영역")}</span>
                    <Select
                        defaultValue=""
                        style={{ width: 200 }}
                        onChange={handleChange}
                    >
                        <Option value="">{t("전체")}</Option>
                        {category.map(item => (
                            <Option value={item.id} key={item.id}>
                                {item.category_name}
                            </Option>
                        ))}
                    </Select>
                    {standardDateList.length > 0 ? (
                        <>
                            <span>{t("기준년월")}</span>
                            <Select
                                defaultValue={standardDateList[0]}
                                style={{ width: '170px' }}
                                placeholder={t("기준년월")}
                                onChange={handleDateChange}
                            >
                                {standardDateList.map(item => (
                                    <Option value={item} key={item}>
                                        {item}
                                    </Option>
                                ))}
                            </Select>
                        </>
                    ) : (
                        <Link to="/Dangers/Risk">
                            <Button>{t("기준년월 설정")}</Button>
                        </Link>
                    )}
                </Space>
            </CertificationSelect>
            {pageInfo.perm_write && (
                <ButtonArea>
                    <Space size="small">
                        <Button
                            type="primary"
                            onClick={handlerProtectionSave}
                            disabled={!isEdit}
                            loading={isSaveLoading}
                        >
                          {t("저장")}
                        </Button>
                        <DownloadButton
                            icon={<DownloadOutlined />}
                            onClick={handleExcelDownload}>
                                {t("결과 다운로드")}
                        </DownloadButton>
                    </Space>
                </ButtonArea>
            )}
        </>
    );
};

export default DesktopDangersProtectionHeader;
