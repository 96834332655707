import { Button, Input, Popconfirm, Select, Table } from 'antd';
import { ICheckDataSourceProps, IColumnsProps, IDataSourceProps } from './type';
import React, { useCallback, useContext, useState } from 'react';
import { ProjectContext } from '@/common/project-provider';
import api from '@/api/api';
import { openNotification } from '@/common/util';
import styled from 'styled-components';
import { useEffect } from 'react';
import defaultInfo from '@/common/site-info';
import Modal from '@/components/modal';
import { useTranslation } from 'react-i18next';

const { Option } = Select;

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IDesktopAdminCategoryTableSubModalProps {
  isModalVisible: boolean;
  setIsModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
  editData: IDataSourceProps[];
}

const Text = styled.p`
  text-align: left;
  textarea {
    vertical-align: top;
    margin: -5px 0 0 0;
    border: 1px solid transparent;
    &:hover {
      border: 1px solid #666666;
    }
  }
`;
const ButtonArea = styled.div`
  text-align: right;
  padding-bottom: 20px;
`;

const getAdminCertCategoryCheckList = async ({ projectId, id }) => {
  const result = await api.getAdminCertCategoryCheckList({
    project_id: projectId,
    id,
  });

  return result.data;
};
const setAdminCertCategoryCheckList = async ({ projectId, id, datas }) => {
  const result = await api.setAdminCertCategoryCheckList({
    project_id: projectId,
    id,
    datas,
  });

  return result.data;
};
const deleteAdminCertCategoryCheckList = async ({
  projectId,
  id,
  check_id,
}) => {
  const result = await api.deleteAdminCertCategoryCheckList({
    project_id: projectId,
    id,
    check_id,
  });

  return result.data;
};

const DesktopAdminCategoryTableSubModal: React.FunctionComponent<
  IDesktopAdminCategoryTableSubModalProps
> = ({ setIsModalVisible, isModalVisible, editData }) => {
  const { t } = useTranslation();
  const { projectId } = useContext(ProjectContext);

  const [detailDatas, setDetailDatas] = useState<ICheckDataSourceProps[]>();

  useEffect(() => {
    if (editData !== null) {
      getAdminCertCategoryCheckList({ projectId, id: editData[0].id }).then(
        datas => {
          setDetailDatas(datas);
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editData, isModalVisible, projectId, t]);

  const handleOk = useCallback(() => {
    setIsModalVisible(false);
  }, [setIsModalVisible]);

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleChange = useCallback((value, record, target) => {
    //console.log(value);

    setDetailDatas(prev => {
      return prev.map(data => {
        if (data.id === record.id) {
          return { ...data, [target]: value, isEdit: true };
        }

        return data;
      });
    });
  }, []);

  const subSaveHandler = useCallback(
    record => {
      setAdminCertCategoryCheckList({
        projectId,
        id: editData[0].id,
        datas: record,
      }).then(datas => {
        const notiText = {
          description: t("완료"),
        };

        openNotification(notiText);
        setDetailDatas(datas);
      });
    },
    [editData, projectId, t]
  );

  const confirm = useCallback(
    (_, record) => {
      deleteAdminCertCategoryCheckList({
        projectId,
        id: editData[0].id,
        check_id: record.id,
      }).then(() => {
        const notiText = {
          description: t("완료"),
        };

        openNotification(notiText);
        setDetailDatas(prev => {
          return prev.filter(item => item.id !== record.id);
        });
      });
    },
    [editData, projectId, t]
  );

  function cancel(e) {
    console.log(e);
  }

  const nextNo = str => {
    let resultNum = '';
    const numSplit = str[str.length - 1]?.check_num.split('.');

    numSplit[numSplit.length - 1] = String(
      Number(numSplit[numSplit.length - 1]) + 1
    );

    numSplit.forEach(num => {
      resultNum = `${resultNum + num}.`;
    });

    return resultNum.replace(/.\s*$/, '');
  };

  const addHandler = () => {
    setDetailDatas(prev => {
      return [
        ...prev,
        {
          category_id: editData[0].id,
          check_desc: '',
          check_num:
            prev.length !== 0 ? nextNo(prev) : `${editData[0].category_num}.1`,
          check_type: '',
          id: null,
          key: prev.length + 1,
          isEdit: true,
        },
      ];
    });
  };

  const columns: IColumnsProps[] = [
    {
      title: 'No',
      dataIndex: 'check_num',
      key: 'check_num',
      align: 'center',
      width: '100px',
      // eslint-disable-next-line react/display-name
      render: value => {
        return value;
      },
    },
    {
      title: t('주요확인사항'),
      dataIndex: 'check_desc',
      key: 'check_desc',
      align: 'center',
      width: 'auto',
      // eslint-disable-next-line react/display-name
      render: (value, record) => (
        <Text>
          <Input.TextArea
            rows={2}
            placeholder={t("주요확인사항을 입력해 주세요.")}
            value={value}
            onChange={event => {
              const text = event.target.value;
              //console.log(text, value);

              handleChange(text, record, 'check_desc');
            }}
          />
        </Text>
      ),
    },
    {
      title: t('유형타입'),
      dataIndex: 'check_type',
      key: 'check_type',
      align: 'center',
      width: '100px',
      // eslint-disable-next-line react/display-name
      render: (value, record) => {
        return (
          <Select
            defaultValue={value}
            onChange={text => handleChange(text, record, 'check_type')}
            style={{ width: 80 }}
          >
            <Option value="결함">{t("결함")}</Option>
            <Option value="확인">{t("확인")}</Option>
          </Select>
        );
      },
    },
    {
      title: '',
      dataIndex: 'edit',
      key: 'edit',
      align: 'center',
      width: '100px',
      // eslint-disable-next-line react/display-name
      render: (_, record) => {
        return !record.isEdit ? (
          <Popconfirm
            title={t("삭제 하시겠습니까?")}
            onConfirm={event => confirm(event, record)}
            onCancel={cancel}
            okText="Yes"
            cancelText="No"
          >
            <Button danger>{t("삭제")}</Button>
          </Popconfirm>
        ) : (
          <Button onClick={() => subSaveHandler(record)}>{t("저장")}</Button>
        );
      },
    },
  ];

  return (
    <Modal
      title={t("주요확인사항")}
      open={isModalVisible}
      onOk={handleOk}
      onCancel={handleCancel}
      width="1000px"
    >
      <ButtonArea>
        <Button type="primary" onClick={addHandler}>
          {t("추가")}
        </Button>
      </ButtonArea>
      <Table
        dataSource={detailDatas}
        columns={columns}
        pagination={false}
        size={defaultInfo.table_size}
      />
    </Modal>
  );
};

export default DesktopAdminCategoryTableSubModal;
