import { AutoComplete, DatePicker, Input, Select } from "antd";
import {
  IEvaluationColumsProps,
  IEvaluationDataProps,
  IAutocompleteOnSelectHandlerProps,
} from "./type";
import React, { useContext, useEffect, useMemo, useState, useCallback } from "react";

import DesktopDangersProtectionHeader from "./desktop-dangers-protection-header";
import DesktopDangersProtectionTable from "./desktop-dangers-protection-table";
import DesktopRiskPlanOwner from "./desktop-dangers-protection-owner";
import DesktopRiskPlanAppr from "./desktop-dangers-protection-appr";

import { IAdminMenuPermissionProps } from "@/common/type";
import { ProjectContext } from "@/common/project-provider";
import api from "@/api/api";
import styled from "styled-components";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

// import { ProjectContext } from '@common/project-provider';
// import api from '@api/api';

const { Option } = Select;
const { TextArea } = Input;

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IDesktopDangersProtectionProps {
  pageInfo: IAdminMenuPermissionProps;
}

const Text = styled.div`
  text-align: left;
`;

// const getRiskResult = async ({ projectId }) => {
//   const result = await api.getRiskResult({ project_id: projectId });

//   return result.data;
// };

const getRiskPlan = async ({ projectId, ym, category_id }) => {
  const result = await api.getRiskPlan({
    project_id: projectId,
    ym,
    category_id,
  });

  return result.data;
};

const getMemberSearch = async ({ project_id, member_name }) => {
  const result = await api.getMemberSearch({
    project_id,
    member_name,
  });

  return result.data;
};

const DesktopDangersProtection: React.FunctionComponent<
  IDesktopDangersProtectionProps
> = ({ pageInfo }) => {
  const { t } = useTranslation();
  const { projectId } = useContext(ProjectContext);
  // const { projectId } = useContext(ProjectContext);
  const [isLoading, setIsLoading] = useState(true);
  const [riskData, setRiskData] = useState<IEvaluationDataProps[]>([]);
  const [categoryId, setCategoryId] = useState(null);
  const [standardDateSelect, setStandardDateSelect] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [membersOptions, setMembersOptions] = useState<{ value: string }[]>([]);

  /*
  const [selectItem, setSelectItem] = useState<IEvaluationDataProps | null>(
    null
  );
  */
  
  //const [open, setOpen] = useState(false);

  useEffect(() => {
    if (standardDateSelect !== "") {
      getRiskPlan({
        projectId,
        category_id: categoryId !== null ? categoryId : "",
        ym: standardDateSelect,
      }).then((datas) => {
        setRiskData(datas.map((item, index) => ({ ...item, key: index })));
        setIsLoading(false);
      });
    }
  }, [categoryId, standardDateSelect, isLoading, projectId, t]);


  const dataSync = useCallback(data => {
    
    setRiskData(prev =>
      prev.map(item => {
          if (item.plan_id === data[0].plan_id) {
              return data[0];
          }
          return item;
      })
    );
    
    //setActiveGuard(true);
  }, []);

  /*
  const showDrawer = () => {
    setOpen(true);
  };
*/
  const selectHandler = (value, record, targetKey) => {
    setIsEdit(true);
    setRiskData((prev) => {
      return prev.map((item) => {
        if (item.id === record.id) {
          return { ...item, [targetKey]: value };
        }
        return { ...item };
      });
    });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onMemberNameSearch = (searchText: string) => {
    setIsEdit(true);
    getMemberSearch({
      project_id: projectId,
      member_name: searchText,
    }).then((datas) => {
      const optionList = datas.map((item, index) => ({
        value: item.member_name,
        key: index,
        id: item.id,
      }));

      setMembersOptions(optionList);
    });
  };

  const columns: IEvaluationColumsProps[] = useMemo(() => {
    return [
      {
        title: t("통제번호"),
        label: t("통제번호"),
        dataIndex: "check_num",
        key: "check_num",
        align: "center",
        width: "130px",
      },
      {
        title: t("항목"),
        label: t("항목"),
        dataIndex: "category_group",
        key: "category_group",
        align: "center",
        width: "130px",
      },
      {
        title: t("위험내용"),
        label: t("위험내용"),
        dataIndex: "threat_desc",
        key: "threat_desc",
        align: "left",
        width: "250px",
      },
      {
        title: t("취약점"),
        label: t("취약점"),
        dataIndex: "vulner_desc",
        key: "vulner_desc",
        align: "left",
        width: "350px",
      },
      {
        title: t("개선방향"),
        label: t("개선방향"),
        dataIndex: "action_desc",
        key: "action_desc",
        align: "left",
        width: "350px",
      },
      {
        title: t("위험도"),
        label: t("위험도"),
        dataIndex: "risk_value",
        key: "risk_value",
        align: "center",
        width: "100px",
      },

      {
        title: t("시급성"),
        label: t("시급성"),
        dataIndex: "urgency",
        key: "urgency",
        align: "center",
        width: "140px",
        // eslint-disable-next-line react/display-name
        render: (text, record) => {
          return pageInfo.perm_write ? (
            <Select
              style={{ width: "100%" }}
              value={text}
              onChange={(val) => {
                selectHandler(val, record, "urgency");
              }}
            >
              <Option value={t("매우높음")}>{t("매우높음")}</Option>
              <Option value={t("높음")}>{t("높음")}</Option>
              <Option value={t("보통")}>{t("보통")}</Option>
              <Option value={t("낮음")}>{t("낮음")}</Option>
              <Option value={t("매우낮음")}>{t("매우낮음")}</Option>
            </Select>
          ) : (
            text
          );
        },
      },
      {
        title: t("구현비용"),
        label: t("구현비용"),
        dataIndex: "cost",
        key: "cost",
        align: "center",
        width: "140px",
        // eslint-disable-next-line react/display-name
        render: (text, record) => {
          return pageInfo.perm_write ? (
            <Select
              style={{ width: "100%" }}
              value={text}
              onChange={(val) => {
                selectHandler(val, record, "cost");
              }}
              allowClear
            >
              <Option value={t("매우높음")}>{t("매우높음")}</Option>
              <Option value={t("높음")}>{t("높음")}</Option>
              <Option value={t("보통")}>{t("보통")}</Option>
              <Option value={t("낮음")}>{t("낮음")}</Option>
              <Option value={t("매우낮음")}>{t("매우낮음")}</Option>
            </Select>
          ) : (
            text
          );
        },
      },
      {
        title: t("구현난이도"),
        label: t("구현난이도"),
        dataIndex: "difficulty",
        key: "difficulty",
        align: "center",
        width: "140px",
        // eslint-disable-next-line react/display-name
        render: (text, record) => {
          return pageInfo.perm_write ? (
            <Select
              style={{ width: "100%" }}
              value={text}
              onChange={(val) => {
                selectHandler(val, record, "difficulty");
              }}
            >
              <Option value={t("매우높음")}>{t("매우높음")}</Option>
              <Option value={t("높음")}>{t("높음")}</Option>
              <Option value={t("보통")}>{t("보통")}</Option>
              <Option value={t("낮음")}>{t("낮음")}</Option>
              <Option value={t("매우낮음")}>{t("매우낮음")}</Option>
            </Select>
          ) : (
            text
          );
        },
      },
      {
        title: t("우선순위"),
        label: t("우선순위"),
        dataIndex: "priority",
        key: "priority",
        align: "center",
        width: "140px",
        // eslint-disable-next-line react/display-name
        render: (text, record) => {
          return pageInfo.perm_write ? (
            <Select
              style={{ width: "100%" }}
              value={text}
              onChange={(val) => {
                selectHandler(val, record, "priority");
              }}
            >
              <Option value={t("단기")}>{t("단기")}</Option>
              <Option value={t("중기")}>{t("중기")}</Option>
              <Option value={t("장기")}>{t("장기")}</Option>
            </Select>
          ) : (
            text
          );
        },
      },
      {
        title: t("조치현황"),
        label: t("조치현황"),
        dataIndex: "status",
        key: "status",
        align: "center",
        width: "140px",
        // eslint-disable-next-line react/display-name
        render: (text, record) => {
          return pageInfo.perm_write ? (
            <Select
              style={{ width: "100%" }}
              value={text !== null ? t(text) : t("미조치")}
              onChange={(val) => {
                selectHandler(val, record, "status");
              }}
            >
              <Option value={t("미조치")}>{t("미조치")}</Option>
              <Option value={t("위험수용")}>{t("위험수용")}</Option>
              <Option value={t("조치예정")}>{t("조치예정")}</Option>
              <Option value={t("조치완료")}>{t("조치완료")}</Option>
            </Select>
          ) : (
            text
          );
        },
      },
      {
        title: t("조치일정"),
        label: t("조치일정"),
        dataIndex: "close_date",
        key: "close_date",
        align: "center",
        width: "200px",
        // eslint-disable-next-line react/display-name
        render: (date, record) => {
          return pageInfo.perm_write ? (
            <DatePicker
              allowClear={false}
              onChange={(val) => {
                selectHandler(
                  dayjs(val).format("YYYY-MM-DD"),
                  record,
                  "close_date"
                );
              }}
              value={record.close_date !== null && dayjs(record.close_date)}
            />
          ) : (
            date
          );
        },
      },
      {
        title: t("비고"),
        label: t("비고"),
        dataIndex: "remark",
        key: "remark",
        align: "center",
        width: "500px",
        // eslint-disable-next-line react/display-name
        render: (remark, record) => {
          return pageInfo.perm_write ? (
            <TextArea
              rows={2}
              defaultValue={remark}
              onChange={(event) => {
                selectHandler(event.target.value, record, "remark");
              }}
            />
          ) : (
            <Text>{remark}</Text>
          );
        },
      },
      {
        title: t("담당자"),
        label: t("담당자"),
        dataIndex: "member_name",
        key: "member_name",
        align: "center",
        width: "100px",
        // eslint-disable-next-line react/display-name
        render: (member_name, record) => {
          return pageInfo.perm_write ? (
            <AutoComplete
              options={membersOptions}
              style={{ width: 130 }}
              onSearch={onMemberNameSearch}
              onSelect={(
                val: string,
                obj: IAutocompleteOnSelectHandlerProps
              ) => {
                console.log(val, obj);

                selectHandler(val, record, "member_name");
                selectHandler(obj.id, record, "member_id");
              }}
              defaultValue={member_name}
            >
              <Input.Search placeholder={t("검색")} />
            </AutoComplete>
          ) : (
            member_name
          );
        },
      },
      {
        title: t('Owner'),
        label: t("Owner"),
        dataIndex: 'owner',
        key: 'owner',
        width: '160px',
        align: 'center',
        render: (_, riskData) => {
            return (
                <DesktopRiskPlanOwner
                    datas={riskData}
                    setIsEdit={setIsEdit}
                    dataSync={dataSync}
                    pageInfo={pageInfo}
                    /* setSelectItem={setSelectItem} 
                    showDrawer={showDrawer}    */
                />
            );
        },
      },
      {
        title: t('승인'),
        label: t("승인"),
        dataIndex: 'appr',
        key: 'appr',
        width: '160px',
        align: 'center',
        render: (_, riskData) => {
            return (
                <DesktopRiskPlanAppr
                    datas={riskData}
                    setIsEdit={setIsEdit}
                    dataSync={dataSync}
                    pageInfo={pageInfo}
                    /* setSelectItem={setSelectItem} 
                    showDrawer={showDrawer}    */
                />
            );
        },
      },
    ];
  }, [membersOptions, onMemberNameSearch, t, dataSync, pageInfo]);

  return (
    <>
      <DesktopDangersProtectionHeader
        riskData={riskData}
        columns={columns}
        setCategoryId={setCategoryId}
        setStandardDateSelect={setStandardDateSelect}
        standardDateSelect={standardDateSelect}
        categoryId={categoryId}
        setIsLoading={setIsLoading}
        isEdit={isEdit}
        pageInfo={pageInfo}
      />
      <DesktopDangersProtectionTable
        riskData={riskData}
        columns={columns}
        isLoading={isLoading}
      />
    </>
  );
};

export default DesktopDangersProtection;
